
/**
 * @roxi/routify 2.7.3
 * File generated Fri Nov 13 2020 19:19:58 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.7.3"
export const __timestamp = "2020-11-13T19:19:58.102Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "ownMeta": {
    "children": [
      {
        "title": "Github",
        "icon": "github",
        "path": "https://github.com/roxiness/routify"
      },
      {
        "title": "Discord",
        "icon": "discord",
        "path": "https://discord.gg/ntKJD5B"
      },
      {
        "title": "Twitter",
        "icon": "twitter",
        "path": "https://twitter.com/routifyjs"
      }
    ]
  },
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "path": "/style-guide/buttons",
          "id": "_styleGuide_buttons",
          "component": () => import('../src/pages/style-guide/buttons.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/style-guide/code",
          "id": "_styleGuide_code",
          "component": () => import('../src/pages/style-guide/code.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/style-guide/colors",
          "id": "_styleGuide_colors",
          "component": () => import('../src/pages/style-guide/colors.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/style-guide/icons",
          "id": "_styleGuide_icons",
          "component": () => import('../src/pages/style-guide/icons.svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "index": false
          },
          "meta": {
            "index": false,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/style-guide/index",
          "id": "_styleGuide_index",
          "component": () => import('../src/pages/style-guide/index.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/style-guide/rich-text",
          "id": "_styleGuide_richText",
          "component": () => import('../src/pages/style-guide/rich-text.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/style-guide/tabs",
          "id": "_styleGuide_tabs",
          "component": () => import('../src/pages/style-guide/tabs.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "index": false
      },
      "meta": {
        "index": false,
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/style-guide",
      "id": "_styleGuide__layout",
      "component": () => import('../src/pages/style-guide/_layout.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "ext": "svx",
          "isPage": true,
          "ownMeta": {
            "my-meta-data": "Hello world"
          },
          "meta": {
            "my-meta-data": "Hello world",
            "frontmatter": {
              "layout": "default"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/wip/comparison-with-sapper",
          "id": "_wip_comparisonWithSapper",
          "component": () => import('../src/pages/wip/comparison-with-sapper.svx').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "index": false
          },
          "meta": {
            "index": false,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/wip/index",
          "id": "_wip_index",
          "component": () => import('../src/pages/wip/index.svelte').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "meta": {
            "frontmatter": {
              "layout": "default"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/wip/migration-guide",
          "id": "_wip_migrationGuide",
          "component": () => import('../src/pages/wip/migration-guide.svx').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "meta": {
            "frontmatter": {
              "layout": "default"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/wip/prefetch-and-cache",
          "id": "_wip_prefetchAndCache",
          "component": () => import('../src/pages/wip/prefetch-and-cache.svx').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "path": "/wip/request-handling",
          "id": "_wip_requestHandling",
          "component": () => import('../src/pages/wip/request-handling.svx').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "meta": {
            "frontmatter": {
              "layout": "default"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/wip/roxi",
          "id": "_wip_roxi",
          "component": () => import('../src/pages/wip/roxi.svx').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "path": "/wip/writing-plugins",
          "id": "_wip_writingPlugins",
          "component": () => import('../src/pages/wip/writing-plugins.svx').then(m => m.default)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "index": false
      },
      "meta": {
        "index": false,
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/wip",
      "id": "_wip__layout",
      "component": () => import('../src/pages/wip/_layout.svelte').then(m => m.default)
    },
    {
      "isIndex": true,
      "isPage": true,
      "ownMeta": {
        "index": false,
        "title": "Home"
      },
      "meta": {
        "index": false,
        "title": "Home",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "index": false
          },
          "meta": {
            "index": false,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/guide/index",
          "id": "_guide_index",
          "component": () => import('../src/pages/guide/index.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "ownMeta": {
            "index": false
          },
          "meta": {
            "index": false,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/guide/metadata",
          "id": "_guide_metadata",
          "component": () => import('../src/pages/guide/metadata.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "index": false
              },
              "meta": {
                "index": false,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/installation/index",
              "id": "_guide_installation_index",
              "component": () => import('../src/pages/guide/installation/index.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "ownMeta": {
                "index": 10
              },
              "meta": {
                "index": 10,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/installation/creating-an-app",
              "id": "_guide_installation_creatingAnApp",
              "component": () => import('../src/pages/guide/installation/creating-an-app.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "ownMeta": {
                "index": 20
              },
              "meta": {
                "index": 20,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/installation/install-to-existing-project",
              "id": "_guide_installation_installToExistingProject",
              "component": () => import('../src/pages/guide/installation/install-to-existing-project.svelte').then(m => m.default)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "index": 10
          },
          "meta": {
            "index": 10,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/guide/installation",
          "id": "_guide_installation__layout",
          "component": () => import('../src/pages/guide/installation/_layout.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "index": false
              },
              "meta": {
                "index": false,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/introduction/index",
              "id": "_guide_introduction_index",
              "component": () => import('../src/pages/guide/introduction/index.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "ownMeta": {
                "index": 10
              },
              "meta": {
                "index": 10,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/introduction/getting-started",
              "id": "_guide_introduction_gettingStarted",
              "component": () => import('../src/pages/guide/introduction/getting-started.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "ownMeta": {
                "index": 20
              },
              "meta": {
                "index": 20,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/introduction/structure",
              "id": "_guide_introduction_structure",
              "component": () => import('../src/pages/guide/introduction/structure.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "ownMeta": {
                "index": 30
              },
              "meta": {
                "index": 30,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/introduction/parameters",
              "id": "_guide_introduction_parameters",
              "component": () => import('../src/pages/guide/introduction/parameters.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "ownMeta": {
                "index": 40
              },
              "meta": {
                "index": 40,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/introduction/layouts",
              "id": "_guide_introduction_layouts",
              "component": () => import('../src/pages/guide/introduction/layouts.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "ownMeta": {
                "index": 40
              },
              "meta": {
                "index": 40,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/introduction/props",
              "id": "_guide_introduction_props",
              "component": () => import('../src/pages/guide/introduction/props.svelte').then(m => m.default)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "index": 20
          },
          "meta": {
            "index": 20,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/guide/introduction",
          "id": "_guide_introduction__layout",
          "component": () => import('../src/pages/guide/introduction/_layout.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "index": false
              },
              "meta": {
                "index": false,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/starter-Template/index",
              "id": "_guide_starterTemplate_index",
              "component": () => import('../src/pages/guide/starter-Template/index.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "ownMeta": {
                "index": 10
              },
              "meta": {
                "index": 10,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/starter-Template/overview",
              "id": "_guide_starterTemplate_overview",
              "component": () => import('../src/pages/guide/starter-Template/overview.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "ownMeta": {
                "index": 20
              },
              "meta": {
                "index": 20,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/starter-Template/SSR",
              "id": "_guide_starterTemplate_SSR",
              "component": () => import('../src/pages/guide/starter-Template/SSR.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "ownMeta": {
                "index": 30
              },
              "meta": {
                "index": 30,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/starter-Template/build",
              "id": "_guide_starterTemplate_build",
              "component": () => import('../src/pages/guide/starter-Template/build.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "ownMeta": {
                "index": 40
              },
              "meta": {
                "index": 40,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/starter-Template/deployment",
              "id": "_guide_starterTemplate_deployment",
              "component": () => import('../src/pages/guide/starter-Template/deployment.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "ownMeta": {
                "index": 50
              },
              "meta": {
                "index": 50,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/guide/starter-Template/configuration",
              "id": "_guide_starterTemplate_configuration",
              "component": () => import('../src/pages/guide/starter-Template/configuration.svelte').then(m => m.default)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "index": 25
          },
          "meta": {
            "index": 25,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/guide/starter-Template",
          "id": "_guide_starterTemplate__layout",
          "component": () => import('../src/pages/guide/starter-Template/_layout.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "ownMeta": {
            "index": 50
          },
          "meta": {
            "index": 50,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/guide/navigation",
          "id": "_guide_navigation",
          "component": () => import('../src/pages/guide/navigation.svelte').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "ownMeta": {
            "index": 60
          },
          "meta": {
            "index": 60,
            "frontmatter": {
              "layout": "default"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/guide/bundling",
          "id": "_guide_bundling",
          "component": () => import('../src/pages/guide/bundling.svx').then(m => m.default)
        },
        {
          "isPage": true,
          "ownMeta": {
            "index": 60,
            "title": "Prefetching - wip"
          },
          "meta": {
            "index": 60,
            "title": "Prefetching - wip",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/guide/prefetching",
          "id": "_guide_prefetching",
          "component": () => import('../src/pages/guide/prefetching.svelte').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "ownMeta": {
            "index": 75
          },
          "meta": {
            "index": 75,
            "frontmatter": {
              "layout": "default"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/guide/server",
          "id": "_guide_server",
          "component": () => import('../src/pages/guide/server.svx').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "ownMeta": {
            "index": 80,
            "title": "FAQ"
          },
          "meta": {
            "index": 80,
            "title": "FAQ",
            "frontmatter": {
              "layout": "default"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/guide/faq",
          "id": "_guide_faq",
          "component": () => import('../src/pages/guide/faq.svx').then(m => m.default)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "index": 10
      },
      "meta": {
        "index": 10,
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/guide",
      "id": "_guide__layout",
      "component": () => import('../src/pages/guide/_layout.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "index": false
          },
          "meta": {
            "index": false,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/docs/index",
          "id": "_docs_index",
          "component": () => import('../src/pages/docs/index.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "ownMeta": {
            "index": false
          },
          "meta": {
            "index": false,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/docs/query-strings",
          "id": "_docs_queryStrings",
          "component": () => import('../src/pages/docs/query-strings.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "index": false
              },
              "meta": {
                "index": false,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/docs/helpers/index",
              "id": "_docs_helpers_index",
              "component": () => import('../src/pages/docs/helpers/index.svelte').then(m => m.default)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "index": 10,
            "children": [
              {
                "title": "url",
                "path": "#url"
              },
              {
                "title": "isActive",
                "path": "#is-active"
              },
              {
                "title": "goto",
                "path": "#goto"
              },
              {
                "title": "params",
                "path": "#params"
              },
              {
                "title": "metatags",
                "path": "#metatags"
              },
              {
                "title": "page",
                "path": "#page"
              },
              {
                "title": "layout",
                "path": "#layout"
              },
              {
                "title": "leftover",
                "path": "#leftover"
              },
              {
                "title": "beforeUrlChange",
                "path": "#before-url-change"
              },
              {
                "title": "afterPageLoad",
                "path": "#after-page-load"
              },
              {
                "title": "isChangingPage",
                "path": "#is-changing-page",
                "tag": "1.8 beta"
              },
              {
                "title": "prefetch",
                "path": "#prefetch",
                "tag": "1.8 beta"
              },
              {
                "title": "focus",
                "path": "#focus"
              },
              {
                "title": "ready",
                "path": "#ready"
              },
              {
                "title": "getConcestor",
                "path": "#get-concestor"
              },
              {
                "title": "getDirection",
                "path": "#get-direction"
              }
            ]
          },
          "meta": {
            "index": 10,
            "children": [
              {
                "title": "url",
                "path": "#url"
              },
              {
                "title": "isActive",
                "path": "#is-active"
              },
              {
                "title": "goto",
                "path": "#goto"
              },
              {
                "title": "params",
                "path": "#params"
              },
              {
                "title": "metatags",
                "path": "#metatags"
              },
              {
                "title": "page",
                "path": "#page"
              },
              {
                "title": "layout",
                "path": "#layout"
              },
              {
                "title": "leftover",
                "path": "#leftover"
              },
              {
                "title": "beforeUrlChange",
                "path": "#before-url-change"
              },
              {
                "title": "afterPageLoad",
                "path": "#after-page-load"
              },
              {
                "title": "isChangingPage",
                "path": "#is-changing-page",
                "tag": "1.8 beta"
              },
              {
                "title": "prefetch",
                "path": "#prefetch",
                "tag": "1.8 beta"
              },
              {
                "title": "focus",
                "path": "#focus"
              },
              {
                "title": "ready",
                "path": "#ready"
              },
              {
                "title": "getConcestor",
                "path": "#get-concestor"
              },
              {
                "title": "getDirection",
                "path": "#get-direction"
              }
            ],
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/docs/helpers",
          "id": "_docs_helpers__layout",
          "component": () => import('../src/pages/docs/helpers/_layout.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "ownMeta": {
            "index": 30
          },
          "meta": {
            "index": 30,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/docs/decorators",
          "id": "_docs_decorators",
          "component": () => import('../src/pages/docs/decorators.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "ownMeta": {
            "index": 30
          },
          "meta": {
            "index": 30,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/docs/transitions",
          "id": "_docs_transitions",
          "component": () => import('../src/pages/docs/transitions.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "ownMeta": {
            "index": 40
          },
          "meta": {
            "index": 40,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/docs/metadata",
          "id": "_docs_metadata",
          "component": () => import('../src/pages/docs/metadata.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "ownMeta": {
            "index": 40,
            "title": "HTML Metatags"
          },
          "meta": {
            "index": 40,
            "title": "HTML Metatags",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/docs/metatags",
          "id": "_docs_metatags",
          "component": () => import('../src/pages/docs/metatags.svelte').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "ownMeta": {
            "index": 50
          },
          "meta": {
            "index": 50,
            "frontmatter": {
              "layout": "default"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/docs/basepath",
          "id": "_docs_basepath",
          "component": () => import('../src/pages/docs/basepath.svx').then(m => m.default)
        },
        {
          "isDir": true,
          "children": [
            {
              "isPage": true,
              "path": "/docs/config/build",
              "id": "_docs_config_build",
              "component": () => import('../src/pages/docs/config/build.svelte').then(m => m.default)
            },
            {
              "isIndex": true,
              "isPage": true,
              "ownMeta": {
                "index": false
              },
              "meta": {
                "index": false,
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/docs/config/index",
              "id": "_docs_config_index",
              "component": () => import('../src/pages/docs/config/index.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/docs/config/runtime",
              "id": "_docs_config_runtime",
              "component": () => import('../src/pages/docs/config/runtime.svelte').then(m => m.default)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "index": 50
          },
          "meta": {
            "index": 50,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/docs/config",
          "id": "_docs_config__layout",
          "component": () => import('../src/pages/docs/config/_layout.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "index": 20
      },
      "meta": {
        "index": 20,
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/docs",
      "id": "_docs__layout",
      "component": () => import('../src/pages/docs/_layout.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "path": "/examples/auth",
          "id": "_examples_auth",
          "component": () => import('../src/pages/examples/auth.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/examples/example-apps",
          "id": "_examples_exampleApps",
          "component": () => import('../src/pages/examples/example-apps.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/examples/generated-navigation",
          "id": "_examples_generatedNavigation",
          "component": () => import('../src/pages/examples/generated-navigation.svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "index": false
          },
          "meta": {
            "index": false,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/examples/index",
          "id": "_examples_index",
          "component": () => import('../src/pages/examples/index.svelte').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "meta": {
            "frontmatter": {
              "layout": "default"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/examples/setup-express-server",
          "id": "_examples_setupExpressServer",
          "component": () => import('../src/pages/examples/setup-express-server.svx').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "meta": {
            "frontmatter": {
              "layout": "default"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/examples/use-tailwind-css",
          "id": "_examples_useTailwindCss",
          "component": () => import('../src/pages/examples/use-tailwind-css.svx').then(m => m.default)
        },
        {
          "isPage": true,
          "ownMeta": {
            "index": 200
          },
          "meta": {
            "index": 200,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/examples/apache-config",
          "id": "_examples_apacheConfig",
          "component": () => import('../src/pages/examples/apache-config.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "index": 40
      },
      "meta": {
        "index": 40,
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/examples",
      "id": "_examples__layout",
      "component": () => import('../src/pages/examples/_layout.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "ext": "svx",
          "isPage": true,
          "meta": {
            "frontmatter": {
              "published": "2020-06-21T21:36:59.459Z",
              "author": "jakobrosenberg",
              "title": "1.8 beta - New dev & production features",
              "summary": "- Prefetching\n- Preloading\n- Bundling\n- Plugins\n- Nollup\n- New documentation\n- Roxi\n\n### Apologies\nThis post should have been published last month, but was delayed by the fact that I only wrote it today.\n",
              "layout": "blogpost"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/blog/1.8-beta",
          "id": "_blog_1_8Beta",
          "component": () => import('../src/pages/blog/1.8-beta.svx').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "meta": {
            "frontmatter": {
              "published": "2020-05-02T12:19:06.574Z",
              "author": "wolfr",
              "title": "An update",
              "summary": "It's been about two weeks since we released the 1.5 version of Routify. You can read all about that in our previous blog post [here](https://routify.dev/blog/announcing-1.5).\n\nOur release was met with a positive reception. We've had lots of people make the jump and give Routify a try, as evidenced by Github's dependency graph which shows over 100 repos using Routify now.\n",
              "layout": "blogpost"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/blog/an-update",
          "id": "_blog_anUpdate",
          "component": () => import('../src/pages/blog/an-update.svx').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "ownMeta": {
            "children": [
              {
                "title": "Github",
                "icon": "github",
                "path": "//github.com/sveltech/routify"
              },
              {
                "title": "Discord",
                "icon": "discord",
                "path": "//discord.gg/ntKJD5B"
              },
              {
                "title": "Twitter",
                "icon": "twitter",
                "path": "//twitter.com/routifyjs"
              }
            ]
          },
          "meta": {
            "children": [
              {
                "title": "Github",
                "icon": "github",
                "path": "//github.com/sveltech/routify"
              },
              {
                "title": "Discord",
                "icon": "discord",
                "path": "//discord.gg/ntKJD5B"
              },
              {
                "title": "Twitter",
                "icon": "twitter",
                "path": "//twitter.com/routifyjs"
              }
            ],
            "frontmatter": {
              "title": "Announcing v1.5",
              "author": "jakobrosenberg",
              "published": "2020-04-20T11:45:06.574Z",
              "summary": "Welcome to the brand-new Routify blog. This is not just the first post on Routify,\nbut also my first ever blog post. Scary. For this cherry popping first post, we’re\nvery happy to announce that 1.5 is finally ready. Among the highlights in this version are:\n\n- Support for meta tags\n- Dynamic basepaths\n- Automatic navigation CLI enhancements\n- Query support\n- Improved starter template\n",
              "layout": "blogpost"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/blog/announcing-1.5",
          "id": "_blog_announcing1_5",
          "component": () => import('../src/pages/blog/announcing-1.5.svx').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/blog/index",
          "id": "_blog_index",
          "component": () => import('../src/pages/blog/index.svelte').then(m => m.default)
        },
        {
          "ext": "svx",
          "isPage": true,
          "meta": {
            "frontmatter": {
              "title": "Routify 2 - Pet Peeve Edition",
              "author": "jakobrosenberg",
              "published": "2020-08-20T12:42:06.574Z",
              "summary": "Our new hash based routing support required a breaking change - and when you have one breaking change, you might as well break everything. So we did. Gone are all the pet peeves.\n",
              "layout": "blogpost"
            },
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/blog/routify-2",
          "id": "_blog_routify2",
          "component": () => import('../src/pages/blog/routify-2.svx').then(m => m.default)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "index": 50,
        "no-tree": true
      },
      "meta": {
        "index": 50,
        "no-tree": true,
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/blog",
      "id": "_blog__layout",
      "component": () => import('../src/pages/blog/_layout.svelte').then(m => m.default)
    }
  ],
  "isLayout": true,
  "meta": {
    "children": [
      {
        "title": "Github",
        "icon": "github",
        "path": "https://github.com/roxiness/routify"
      },
      {
        "title": "Discord",
        "icon": "discord",
        "path": "https://discord.gg/ntKJD5B"
      },
      {
        "title": "Twitter",
        "icon": "twitter",
        "path": "https://twitter.com/routifyjs"
      }
    ],
    "recursive": true,
    "preload": false,
    "prerender": true
  },
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

